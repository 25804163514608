<template>
  <div class="plk_layerupdate">
    <Menu></Menu>
    <div class="viewer">
      <h5>{{$t('designer.cms.layers.update.title')}}</h5>
      <form @submit.prevent="updateAbstraction()">
        <AbstractionCreator v-if="abstraction" :abstraction="abstraction" @updateAbstraction="setAbstraction" class="twelve columns mb-5"/>
        <Uploader class="mb-5" :simpleFile="true" acceptType="application/json" @loadFile="loadedFile"></Uploader>
        <button type="submit" class="w-100 pull-right">{{$t('designer.cms.layers.update.btnupdate')}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import Menu from '../../components/menus/Menu'
import mixincms from '@/mixins/cms'
import { VIEWS } from '@/router/design'
import AbstractionCreator from './components/AbstractionCreator'
import Uploader from '@/views/design/components/uploaders/Uploader'

export default {
  name: 'layerupdate',
  components: {
    Menu,
    AbstractionCreator,
    Uploader
  },
  mixins: [mixincms],
  data () {
    return {
      abstraction: undefined,
      file: undefined
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadAbstractions()
    },
    loadedFile ({ content: { file } }) {
      this.file = file
    },
    async loadAbstractions () {
      try {
        if (!this.$route.query.folder || !this.$route.query.abstraction) {
          throw new Error()
        }
        const element = await this.$servicesPLK.cms.getElementFile(this.$route.query.folder, this.$route.query.abstraction)
        if (!this.haveRelation(element.action, 'update')) {
          throw new Error()
        }
        if (element.resource.connection) {
          const connection = await this.$servicesPLK.cms.getConnectionById(element.resource.connection)
          Vue.set(this, 'abstraction', { ...element.resource, ...{ folderConnection: connection.resource.idFolder } })
        } else {
          Vue.set(this, 'abstraction', element.resource)
        }
      } catch (e) {
        await this.$router.push({
          name: VIEWS.CMS.LAYER_LIST.name
        })
      }
    },
    setAbstraction ({ abstraction }) {
      abstraction.idFolder = abstraction.folderAbstraction
      this.abstraction = { ...this.abstraction, ...abstraction }
    },
    updateAbstraction () {
      this.$modal.show('dialog', {
        title: this.$t('designer.cms.layers.update.dialog.updatelayer.title'),
        text: this.$t('designer.cms.layers.update.dialog.updatelayer.text'),
        buttons: [
          {
            title: this.$t('designer.cms.layers.update.dialog.updatelayer.discard'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('designer.cms.layers.update.dialog.updatelayer.confirm'),
            handler: async () => {
              this.$modal.hide('dialog')
              if (this.$route.query.folder && this.$route.query.abstraction) {
                await this.$servicesPLK.cms.updateLayer(this.$route.query.folder, this.$route.query.abstraction, this.abstraction)
              }
              if (this.file) {
                await this.$servicesPLK.cms.updateLayerContent(this.$route.query.folder, this.$route.query.abstraction, this.file)
              }
              await this.$router.push({
                name: VIEWS.CMS.LAYER_LIST.name
              })
            }
          }
        ]
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_layerupdate {
    background: #f8f9fa;
    height: 100vh;
    .viewer {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
    }
  }
</style>
